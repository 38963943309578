import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
Swiper.use([Autoplay, Navigation]);

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css";

// Initialize Swiper with progressbar pagination
$(document).ready(function () {
  const swiperLarge = new Swiper(".swiper-container", {
    direction: "horizontal",
    slidesPerView: 1,
    spaceBetween: 49,
    loop: false,
    navigation: {
      nextEl: `.swiper-button-next-testimonial`,
      prevEl: `.swiper-button-prev-testimonial`,
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar", // Progressbar type
    },
    autoplay: {
      delay: 9000,
      disableOnInteraction: false,
    },
  });

  // Update pagination and add classes on slide change
  swiperLarge.on("slideChange", function () {
    const currentIndex = swiperLarge.activeIndex;
    const totalItems = swiperLarge.slides.length;

    // Remove active and completed classes from all pagination items
    $(".pagination").removeClass("active completed");

    // Add active class to the current pagination item
    $($(".pagination")[currentIndex]).addClass("active");

    // Mark the previous pagination item as completed
    const prevIndex = (currentIndex - 1 + totalItems) % totalItems; // Wrap around to the end
    const nextIndex = (currentIndex + 1 + totalItems) % totalItems; // Wrap around to the end
    $($(".pagination")[prevIndex]).addClass("completed");
    $($(".pagination")[nextIndex]).addClass("completed");
  });

  // Initialize the first pagination item
  setTimeout(() => {
    $(".pagination").first().addClass("active");
  }, 295);

  setTimeout(() => {
    $(".pagination").first().removeClass("active").addClass("completed");
  }, 9200); // Main timeout

  /* -----------------------------------
---------------------------------------------------------------
--------------------------------------
------------------ NEXT SET OF SWIPER INITIALISED  */

  // Loop through each swiper container with the unique initialization class
  document
    .querySelectorAll(".swiper-cards-container")
    .forEach(function (container) {
      // Extract the unique ID from the class (assuming it's added as a unique class)
      const uniqueClass = Array.from(container.classList).find((cls) =>
        cls.startsWith("swiper-initialised-")
      );

      if (uniqueClass) {
        const uniqueId = uniqueClass.split("-").pop(); // Split the class to get the unique ID

        console.log(
          `Initializing Swiper for container with unique ID: ${uniqueId}`
        );

        // Initialize Swiper for this particular container
        const swiperCards = new Swiper(`.swiper-initialised-${uniqueId}`, {
          direction: "horizontal",
          slidesPerView: 1, // Default value for screens less than 1020px
          autoplay: {
            delay: 9000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: `.swiper-button-next-${uniqueId}`,
            prevEl: `.swiper-button-prev-${uniqueId}`,
          },
          breakpoints: {
            1020: {
              slidesPerView: 3,
              autoplay: {
                delay: 9000, // Keep autoplay consistent on larger screens if needed
                disableOnInteraction: false,
              },
              navigation: {
                nextEl: `.swiper-button-next-${uniqueId}`,
                prevEl: `.swiper-button-prev-${uniqueId}`,
              },
            },
          },
          loop: false, // No need to loop here
          spaceBetween: 32,
        });

        // Pagination and class management
        swiperCards.on("slideChange", function () {
          const currentIndex = swiperCards.activeIndex;
          const totalItems = swiperCards.slides.length;

          // Remove active and completed classes from all pagination items
          $(".pagination-grids").removeClass("active completed");
          $(".pagination-grids-desktop").removeClass("active completed");

          // Add active class to the current pagination item
          $($(".pagination-grids")[currentIndex]).addClass("active");
          $($(".pagination-grids-desktop")[currentIndex]).addClass("active");

          // Mark the previous pagination item as completed
          const prevIndex = (currentIndex - 1 + totalItems) % totalItems; // Wrap around to the end
          const nextIndex = (currentIndex + 1 + totalItems) % totalItems; // Wrap around to the end
          $($(".pagination-grids")[prevIndex]).addClass("completed");
          $($(".pagination-grids")[nextIndex]).addClass("completed");
          $($(".pagination-grids-desktop")[prevIndex]).addClass("completed");
          $($(".pagination-grids-desktop")[nextIndex]).addClass("completed");
        });

        // Initialize the first pagination item
        setTimeout(() => {
          $(".pagination-grids").first().addClass("active");
        }, 295);

        setTimeout(() => {
          $(".pagination-grids")
            .first()
            .removeClass("active")
            .addClass("completed");
        }, 9200); // Main timeout

        // desktop
        // Initialize the first pagination item
        setTimeout(() => {
          $(".pagination-grids-desktop").first().addClass("active");
        }, 295);

        setTimeout(() => {
          $(".pagination-grids-desktop")
            .first()
            .removeClass("active")
            .addClass("completed");
        }, 9200); // Main timeout
      } else {
        console.error(
          "Unique swiper-initialised class not found on container:",
          container
        );
      }
    });
});
